import React, { useState, Fragment } from "react";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  useSearchBox,
  Highlight,
  Configure,
  useHits,
} from "react-instantsearch-hooks-web";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

function CustomSearchBox(props) {
  const { query, refine, clear } = useSearchBox(props);
  const { hits, results, sendEvent } = useHits(props);

  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    refine(event.target.value);
  };

  function handleOpen() {
    setOpen(!open);
  }

  return (
    <>
      <div
        onClick={handleOpen}
        className="flex m-5 items-center space-between cursor-pointer"
      >
        <MagnifyingGlassIcon
          className="flex-shrink-0 z-10 h-6 w-6 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
        <span className="sr-only">Search for products in the shop</span>
      </div>
      <Transition.Root
        show={open}
        as={Fragment}
        afterLeave={() => clear}
        appear
      >
        <Dialog as="div" className="relative z-40" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 backdrop-blur-sm bg-aocBg1 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Combobox onChange={(item) => (window.location = item.url)}>
                  <div className="relative">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <Combobox.Input
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-aocBg1 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="Search..."
                      onChange={handleChange}
                    />
                  </div>

                  {hits.length > 0 && (
                    <Combobox.Options
                      static
                      className="max-h-96 scroll-py-3 overflow-y-auto p-1"
                    >
                      {hits.map((item, index) => (
                        <>
                          {item.inStock ? (
                            <>
                              {item.category[0] != "EC18" ? (
                                <a href={`/products/${item.slug}`}>
                                  <Combobox.Option
                                    key={item.sku}
                                    value={item.node}
                                    className={({ active }) =>
                                      classNames(
                                        "flex cursor-pointer select-none rounded-xl p-2.5",
                                        active && "bg-gray-100"
                                      )
                                    }
                                  >
                                    <div className=" flex h-14 w-14 flex-none items-center justify-center rounded-lg">
                                      <img
                                        className="rounded-lg"
                                        src={
                                          process.env.GATSBY_BASE_URL +
                                          item.images[0].imageSrc
                                        }
                                      />
                                    </div>
                                    <div className="ml-2 flex-auto">
                                      <p className="text-sm font-md text-aocBg1">
                                        <Highlight
                                          hit={results.hits[index]}
                                          attribute="title"
                                          classNames="bg-white hover:bg-gray-100"
                                        />
                                      </p>
                                      <p className="text-sm font-md text-aocBg1">
                                        ${item.price}
                                      </p>
                                    </div>
                                  </Combobox.Option>
                                </a>
                              ) : null}
                            </>
                          ) : null}
                        </>
                      ))}
                    </Combobox.Options>
                  )}

                  {hits.length === 0 && (
                    <div className="pt-10 bg-gray-100 mx-auto flex flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
                      <div className="flex flex-shrink-0 justify-center">
                        <span className="inline-flex animate-pulse rounded-full items-center bg-aoc">
                          <lord-icon
                            target="div"
                            trigger="loop"
                            src="https://cdn.lordicon.com/fibwwwxi.json"
                            colors="primary:#752424,secondary:#752424"
                            stroke="75"
                            style={{ width: "80px", height: "80px" }}
                          ></lord-icon>
                        </span>
                      </div>
                      <div className="py-5">
                        <div className="text-center">
                          <h1 className="mt-1 text-2xl font-bold tracking-tight text-aocBg1 sm:text-2xl">
                            No Results Found
                          </h1>
                          <p className="mt-2 text-base text-gray-500">
                            Sorry, we couldn't find any products '
                            <strong>{query}</strong>'
                          </p>{" "}
                          <br></br>
                          <span className="mt-2 text-base text-aocBg1">
                            Cant find what your looking for?
                          </span>
                          <a
                            href={`/contact`}
                            className="rounded-md ml-2 bg-darkAoc px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-halfAoc focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                          >
                            Contact Us
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const searchClient = algoliasearch(
  "GCNSEZTR4P",
  "51ed7832ecad73ef39c1018c9474c7c2"
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SearchFunction() {
  return (
    <InstantSearch indexName="PROD_AOC" searchClient={searchClient}>
      <Configure analytics={true} hitsPerPage={5} />
      <CustomSearchBox />
    </InstantSearch>
  );
}
