import React, { useState, Fragment } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Cart from "../shop/cart";
import logoBlack from "../../images/logo-black.webp";
import Search from "./search";
import { Link } from "gatsby";

const navigation = [
  { name: "Home", href: "" },
  { name: "Flavours", href: "flavours" },
  { name: "Shop", href: "shop" },
  { name: "Wedding & Engagement", href: "wedding" },
  { name: "Testimonials", href: "testimonials" },
  { name: "Contact Us", href: "contact" },
];

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-white border-b-2">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-3 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link
            style={{ width: "150px" }}
            to="/"
            className="flex-shrink-0 flex items-center"
          >
            <span className="sr-only">All Occasion Cakes</span>
            <img className="w-full lg:block" src={logoBlack} alt="" />
          </Link>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={`/` + item.href}
              className="border-transparent text-aocBg1 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
              activeStyle={{ borderColor: "#752424", color: "#752424" }}
            >
              {item.name}
            </Link>
          ))}
        </div>
        <div className="flex flex-1 items-center justify-end gap-x-6">
          <div className="flex items-center">
            <div className="">
              <Search />
            </div>
            <div>
              {" "}
              <Cart />
            </div>
          </div>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-aocBg1/10">
          <div className="flex items-center gap-x-6">
            <Link
              style={{ width: "150px" }}
              to="/"
              className="flex-shrink-0 flex items-center"
            >
              <span className="sr-only">All Occasion Cakes</span>
              <img className="w-full lg:block" src={logoBlack} alt="" />
            </Link>
            <Link
              href="/contact"
              className="ml-auto rounded-md bg-darkAoc px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-halfAoc focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-darkAoc"
            >
              Contact Us
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    onClick={() => setMobileMenuOpen(false)}
                    to={`/` + item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-aocBg1 hover:bg-gray-50"
                    activeStyle={{ borderColor: "#752424", color: "#752424" }}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
