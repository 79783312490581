import "./src/styles/global.css";
import React from "react";
import { CartProvider } from "react-use-cart";
import Layout from "./src/components/common/layout";
import MessengerChat from './src/components/common/facebookChatPopUp'

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}<MessengerChat /></Layout>;
};

export const wrapRootElement = ({ element }) => {
  return <CartProvider>{element}</CartProvider>;
};
