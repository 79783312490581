import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, ShoppingBagIcon } from "@heroicons/react/24/outline";
import { Link } from "gatsby";
import { useCart } from "react-use-cart";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

export const Cart = () => {
  const [open, SetOpen] = useState(false);

  const { totalUniqueItems, updateItemQuantity, removeItem, cartTotal, items } =
    useCart();

  const [count, setCount] = useState(0);

  function handleOpen() {
    SetOpen(!open);
  }

  useEffect(() => {
    if (totalUniqueItems != 0 && count === 1) {
      handleOpen();
    }
    setCount(1);
  }, [totalUniqueItems]);

  return (
    <>
      <Helmet>
        <script src="https://cdn.lordicon.com/libs/frhvbuzj/lord-icon-2.0.2.js" />
      </Helmet>

      <div
        className="flex m-5 items-center space-between cursor-pointer"
        onClick={handleOpen}
      >
        <ShoppingBagIcon
          className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
        <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
          {totalUniqueItems}
        </span>
        <span className="sr-only">items in cart, view bag</span>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden z-50"
          onClose={() => handleOpen()}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                    <div className="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-aocBg1">
                          Shopping cart
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={() => handleOpen()}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          <ul
                            role="list"
                            className="-my-6 divide-y divide-gray-200"
                          >
                            {items.map((product, index) => (
                              <motion.div
                                className=""
                                initial={{ opacity: 0, y: 150 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{
                                  duration: 0.8,
                                  delay: index * 0.2,
                                }}
                              >
                                <li key={product.id} className="py-6 flex">
                                  <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                                    <img
                                      src={product.details[0].img_src}
                                      alt={product.details[0].img_alt}
                                      className="w-full h-full object-center object-cover"
                                    />
                                  </div>

                                  <div className="ml-4 flex-1 flex flex-col">
                                    <div>
                                      <div className="flex justify-between text-base font-medium text-aocBg1">
                                        <h3>
                                          <a href={product.href}>
                                            {product.name}
                                          </a>
                                        </h3>
                                        <p className="ml-4">
                                          {" "}
                                          ${product.price * product.quantity}
                                        </p>
                                      </div>
                                      <div className="flex justify-between text-base text-aocBg1">
                                        <i className="text-sm text-gray-500">
                                          {product.sku}
                                        </i>
                                      </div>
                                    </div>

                                    <div className="flex-1 flex items-end justify-between text-sm">
                                      <label
                                        htmlFor="quantity"
                                        className="sr-only"
                                      >
                                        Quantity
                                      </label>
                                      <select
                                        onChange={(event) =>
                                          updateItemQuantity(
                                            product.id,
                                            event.target.value
                                          )
                                        }
                                        id="quantity"
                                        name="quantity"
                                        value={product.quantity}
                                        className="rounded-md border border-gray-300 text-base font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      >
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                      </select>

                                      <div className="flex">
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            removeItem(product.id);
                                          }}
                                          type="button"
                                          className="font-medium text-gray-600 hover:text-darkAoc"
                                        >
                                          <lord-icon
                                            trigger="hover"
                                            src="https://cdn.lordicon.com/jmkrnisz.json"
                                            colors="primary:#1B2E3C,secondary:#1B2E3C"
                                            style={{
                                              width: "25px",
                                              height: "25px",
                                            }}
                                          ></lord-icon>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </motion.div>
                            ))}
                            {totalUniqueItems === 0 && (
                              <div className="h-full w-full flex items-center justify-center pt-5 mt-5">
                                <img src="https://assets.materialup.com/uploads/87d4df96-a55f-4f4b-9a17-a696eded97f3/preview.gif" />
                              </div>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                      <div className="flex justify-between text-base font-medium text-aocBg1">
                        <p>Subtotal</p>
                        <p>${cartTotal}</p>
                      </div>
                      <p className="mt-0.5 text-sm text-gray-500">
                        Shipping calculated at checkout.
                      </p>
                      <div className="mt-6">
                        <Link
                          onClick={() => handleOpen()}
                          disabled={totalUniqueItems === 0 ? "disabled" : ""}
                          to="/checkout"
                          className={
                            totalUniqueItems === 0
                              ? "w-full flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-gray bg-gray-300"
                              : "flex w-full justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-aoc bg-darkAoc hover:bg-aoc hover:text-darkAoc"
                          }
                        >
                          Checkout
                        </Link>
                      </div>
                      <div className="mt-6 flex justify-center text-sm text-center text-gray-500">
                        <p>
                          or{" "}
                          <button
                            type="button"
                            className="text-darkAoc font-medium hover:text-halfAoc"
                            onClick={() => handleOpen()}
                          >
                            Continue Shopping
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Cart;
