import React, { useState, useEffect } from "react";
import Header from "./header2";
import Footer from "./footer";
import CookieBanner from "./cookieBanner";

export default function Layout({ children }) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div>
      <Header />
      {loading ? (
        <div className="h-screen flex items-center justify-center">
          <img src="https://i.pinimg.com/originals/b2/9f/d6/b29fd602ce98cb03b04b7afc8d5d25e3.gif" />
        </div>
      ) : (
        <>
          {children}
          <CookieBanner />
        </>
      )}
      <Footer />
    </div>
  );
}
