import React, { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "gatsby";
import Helmet from "react-helmet";

export default function Example() {
  const [cookieShow, setCookieShow] = useState(
    localStorage.getItem("cookie_consent") == null ? false : true
  );

  return (
    <>
      <Helmet>
        <script src="https://cdn.lordicon.com/libs/frhvbuzj/lord-icon-2.0.2.js" />
      </Helmet>

      {!cookieShow ? (
        <div className="z-[999999999999999999] fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="p-2 rounded-lg bg-darkAoc shadow-lg sm:p-3">
              <div className="flex items-center justify-between flex-wrap">
                <div className="w-0 flex-1 flex items-center">
                  <span className="flex p-1 rounded-lg bg-aoc">
                    <lord-icon
                      target="div"
                      trigger="hover"
                      src="https://cdn.lordicon.com/ycplpezb.json"
                      colors="primary:#752424,secondary:#752424"
                      style={{ width: "40px", height: "40px" }}
                      stroke="75"
                    ></lord-icon>
                  </span>
                  <p className="ml-2 text-white truncate">
                    <span className="md:hidden text-sm text-center">
                      We use cookies to provide you with an <br /> enhanced
                      browsing and shopping experience.
                    </span>
                    <span className="hidden md:inline">
                      We use cookies to provide you with an enhanced browsing
                      and shopping experience.
                    </span>
                  </p>
                </div>
                <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                  <Link
                    to="/privacy-policy"
                    className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-darkAoc bg-white hover:bg-indigo-50"
                  >
                    Learn more
                  </Link>
                </div>
                <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                  <button
                    type="button"
                    className="-mr-1 flex p-2 rounded-md hover:bg-halfAoc focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={() => {
                      localStorage.setItem("cookie_consent", true);
                      setCookieShow(true);
                    }}
                  >
                    <span className="sr-only">Dismiss</span>
                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
